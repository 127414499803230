import React, { useState } from 'react';
import {
  ChakraProvider,
  Input, 
  Button,
} from '@chakra-ui/react';
import { useNavigate } from "react-router-dom";
import { Auth } from 'aws-amplify';
import awsconfig from '../aws-exports';

Auth.configure(awsconfig);

function Login() {  
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isLoggingIn, setIsLogginIn] = useState(false);

  return (
    <ChakraProvider>
      <Input placeholder='Email' onChange={(e) => setEmail(e.target.value)} />
      <Input placeholder='Password' type='password' onChange={(e) => setPassword(e.target.value)} />
      <Button colorScheme='blue' isLoading={isLoggingIn} onClick={() => {
        setIsLogginIn(true);
        Auth.signIn(email, password)
          .then((user) => {
            setIsLogginIn(false);
            localStorage.setItem('cars-4-u-user-token', user.signInUserSession.idToken.jwtToken);
            navigate('/admin')
          })
          .catch((err) => {
            setIsLogginIn(false);
          });
      }}>Login</Button>
    </ChakraProvider>
  );
}

export default Login;
