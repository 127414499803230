import axios from 'axios';

export const getCars = () => new Promise((resolve) => {
    axios.get('https://gfdbcycbi1.execute-api.ap-southeast-2.amazonaws.com/dev/cars')
    .then(function (response) {
      resolve(response.data);
    })
    .catch(function (error) {
      resolve([]);
    })
});

export const createCar = (params) => new Promise((resolve) => {
    axios.post('https://gfdbcycbi1.execute-api.ap-southeast-2.amazonaws.com/dev/cars', params, {
      headers: {'Authorization': `Bearer ${localStorage.getItem('cars-4-u-user-token')}`}
    })
    .then(function (response) {
      resolve(response.data);
    })
    .catch(function (error) {
      resolve([]);
    })
});

export const deleteCar = (carId, make) => new Promise((resolve) => {
    axios.post(`https://gfdbcycbi1.execute-api.ap-southeast-2.amazonaws.com/dev/cars/delete`, 
    {
        carId,
        make
    },
    {
      headers: {'Authorization': `Bearer ${localStorage.getItem('cars-4-u-user-token')}`}
    })
    .then(function (response) {
      resolve(response.data);
    })
    .catch(function (error) {
      resolve([]);
    })
});