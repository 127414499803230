/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/aria-proptypes */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import {
  extendTheme,
  useDisclosure,
  ChakraProvider,
  Image, 
  Flex, 
  Spacer, 
  Text, 
  Center, 
  Grid, 
  GridItem, 
  Button, 
  Wrap, 
  WrapItem, 
  Box, 
  Badge, 
  Select,
  RangeSlider,
  RangeSliderTrack,
  RangeSliderFilledTrack,
  RangeSliderThumb,
  SimpleGrid,
  Link,
  Spinner,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react';
import { mode } from "@chakra-ui/theme-tools";
import { SocialIcon } from 'react-social-icons';
import mainImage from '../assets/main.jpg';
import logo from '../assets/cars4u-logo.png';
import logoGif from '../assets/cars4u-logo.gif';
import about from '../assets/About.jpg';
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { getCars } from '../lib/api';

const theme = extendTheme({
  styles: {
    global: (props) => ({
      body: {
        touchAction: mode('pan-y','pan-y')(props),
        width: mode('fit-content','fit-content')(props),
        bg: mode('#F9F9F8','#F9F9F8')(props),
      }
    })
  },
})

function Home() {
  const [cars, setCars] = useState([]);
  const [canOpenModal, setCanOpenModal] = useState(false);
  const [filteredCars, setFilteredCars] = useState([]);
  const [selectedCar, setSelectedCar] = useState(null);
  const [selectedMake, setSelectedMake] = useState('');
  const [makes, setMakes] = useState([]);
  const [models, setModels] = useState([]);
  const [selectedModel, setSelectedModel] = useState('');
  const [priceLow, setPriceLow] = useState(10000);
  const [priceHigh, setPriceHigh] = useState(500000);
  const [odometerLow, setOdometerLow] = useState(0);
  const [odometerHigh, setOdometerHigh] = useState(300000);
  const [isLoading, setIsLoading] = useState(true);
  const { isOpen, onOpen, onClose } = useDisclosure()

  const search = () => {
    let filtered = [...cars]

    // Filter make
    if (selectedMake != null && selectedMake !== '') {
      filtered = filtered.filter((car) => car.make === selectedMake)
    }

    // Filter model
    if (selectedModel != null && selectedModel !== '') {
      filtered = filtered.filter((car) => car.model === selectedModel)
    }
    
    // Filter price
    // filtered = filtered.filter((car) => car.price >= (priceLow - (priceLow * 0.1)) && car.price <= (priceHigh + (priceHigh * 0.1)))
    filtered = filtered.filter((car) => Number(car.price) >= priceLow && car.price <= priceHigh)
    
    // Filter odometer
    filtered = filtered.filter((car) => Number(car.odometer) >= odometerLow && car.odometer <= odometerHigh)

    // Set results
    setFilteredCars(filtered)
  }

  const setModalAvailability = () => {
    const { innerWidth: width } = window;
    setCanOpenModal(width > 600);
  }

  useEffect(() => {
    setIsLoading(true);
    setModalAvailability();
    getCars().then((res) => {
      const { cars } = res;
      setCars(cars);
      setIsLoading(false);
    })

    function handleResize() {
      setModalAvailability();
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    setFilteredCars(cars);
    setMakes([...new Set(cars.length > 0 ? cars.map((car) => car.make) : [])]);
  }, [cars]);

  useEffect(() => {
    if (canOpenModal === true && selectedCar != null) {
      onOpen()
    }
  }, [selectedCar]);

  useEffect(() => {
    if (isOpen === false) {
      setSelectedCar(null)
    }
  }, [isOpen]);

  useEffect(() => {
    setModels(selectedMake !== '' ? [...new Set(cars
      .filter((car) => car.make === selectedMake)
      .map((car) => car.model))] : [])

    if (selectedModel !== ''){
      setSelectedModel('')
    } else {
      search()
    }
  }, [selectedMake]);

  useEffect(() => {
    search()
  }, [selectedModel]);

  const goToSearch = () => {
    var nav = document.getElementById('nav');
    var element = document.getElementById('search');
    var headerOffset = nav.getBoundingClientRect().height + 10;
    var elementPosition = element.getBoundingClientRect().top;
    var offsetPosition = elementPosition + window.pageYOffset - headerOffset;
  
    window.scrollTo({
         top: offsetPosition,
         behavior: "smooth"
    });
  }

  const goToContact = () => {
    var nav = document.getElementById('nav');
    var element = document.getElementById('contact');
    var headerOffset = nav.getBoundingClientRect().height + 10;
    var elementPosition = element.getBoundingClientRect().top;
    var offsetPosition = elementPosition + window.pageYOffset - headerOffset;
  
    window.scrollTo({
          top: offsetPosition,
          behavior: "smooth"
    });
  }

  return (
    <ChakraProvider theme={theme}>
      <Flex id='nav' zIndex={10} as="header" borderBottom='1px' borderColor='#dcdcdc' position="fixed" width='100%' backgroundColor='#F9F9F8' marginTop='-100px'>
        <Center>
          <Image src={logo} objectFit='cover' maxHeight='80px' style={{ marginLeft: '25px', marginTop: '15px', marginBottom: '12px' }} />
        </Center>
        <Spacer/>
        <Center>
          <Wrap margin={3} justify='center'>
            <WrapItem>
              <SocialIcon url="mailto:mathew1cars4u@gmail.com" fgColor='white' bgColor='orange'/>
            </WrapItem>
            <WrapItem>
              <SocialIcon url="tel:0839650233" fgColor='white'/>
            </WrapItem>
            <WrapItem>
              <SocialIcon url="https://www.facebook.com/Cars4uboksburg" fgColor='white'/>
            </WrapItem>
          </Wrap>
        </Center>
      </Flex>

      <div style={{ height: '100%', position: 'relative', width: '100vw', minHeight: '400px', marginTop: '100px', display: 'flex', justifyContent: 'center', alignItems: 'center'  }} >
        <Image src={mainImage} position='absolute' height='100%' width='100%' minWidth='400px' objectFit='cover' />
        <div style={{ height: '100%', position: 'absolute', width: '100%', backgroundColor: 'black', opacity: 0.7 }}></div>

        <div style={{ position: 'absolute' }}>
          <Center>
            <Text width='100%' zIndex={1} textAlign='center' fontSize={[ '58px', '62px' ]} paddingLeft='10' paddingRight='10' paddingBottom='10' color='white'>We buy and sell used cars</Text>
          </Center>

          <Center height='100%'>  
            <Button colorScheme='whiteAlpha' marginRight={2} size={['lg']} onClick={() => goToSearch()}>
              View Inventory
            </Button>  
            <Button colorScheme='whiteAlpha' marginLeft={2} size={['lg']} onClick={() => goToContact()}>
              Contact Us
            </Button>
          </Center>
        </div>
      </div>

      <Wrap id='search' spacing='10px' justify='center' margin='30px' width='auto'>
        <WrapItem>
          <Box maxW='sm' borderWidth='1px' borderRadius='lg' overflow='hidden' padding='10px' minWidth='280px' backgroundColor='white' minHeight='100px'>
            <Box
              color='gray.500'
              fontWeight='semibold'
              letterSpacing='wide'
              fontSize='xs'
              textTransform='uppercase'
              marginTop='5px'
            >
              Make
            </Box>
            <Select placeholder='Select make' value={selectedMake} onChange={(e) => setSelectedMake(e.target.value)}>
              {makes.length && makes.map((make, index) => {
                return <option key={index} value={make}>{ make }</option>
              })}
            </Select>
          </Box>
        </WrapItem>
        <WrapItem>
          <Box maxW='sm' borderWidth='1px' borderRadius='lg' overflow='hidden' padding='10px' minWidth='280px' backgroundColor='white' minHeight='100px'>
            <Box
              color='gray.500'
              fontWeight='semibold'
              letterSpacing='wide'
              fontSize='xs'
              textTransform='uppercase'
              marginTop='5px'
            >
              Model
            </Box>
            <Select placeholder='Select model' value={selectedModel} disabled={models.length === 0} onChange={(e) => setSelectedModel(e.target.value)}>
              {models.length && models.map((model, index) => {
                return <option key={index} value={model}>{ model }</option>
              })}
            </Select>
          </Box>
        </WrapItem>
        <WrapItem>
          <Box maxW='sm' borderWidth='1px' borderRadius='lg' overflow='hidden' padding='10px' minWidth='280px' backgroundColor='white' minHeight='100px'>
            <Box
              color='gray.500'
              fontWeight='semibold'
              letterSpacing='wide'
              fontSize='xs'
              textTransform='uppercase'
              marginTop='5px'
            >
              Price
            </Box>

            <Flex>
              <Box
                color='gray'
                fontWeight='semibold'
                letterSpacing='wide'
                fontSize='md'
                textTransform='uppercase'
                marginTop='5px'
              >
                {`R${Number(priceLow).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`}
              </Box>
              <Spacer/>
              <Box
                color='gray'
                fontWeight='semibold'
                letterSpacing='wide'
                fontSize='sm'
                textTransform='uppercase'
                marginTop='5px'
              >
                -
              </Box>
              <Spacer/>
              <Box
                color='gray'
                fontWeight='semibold'
                letterSpacing='wide'
                fontSize='md'
                textTransform='uppercase'
                marginTop='5px'
              >
                {`R${Number(priceHigh).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`}
              </Box>
            </Flex>

            <RangeSlider aria-label={['min', 'max']} 
              min={10000} 
              max={500000} 
              step={10000} 
              defaultValue={[10000, 500000]}
              onChange={(vals) => {
                setPriceLow(vals[0])
                setPriceHigh(vals[1])
              }}
              onChangeEnd={() => search()}>
              <RangeSliderTrack>
                <RangeSliderFilledTrack />
              </RangeSliderTrack>
              <RangeSliderThumb index={0} backgroundColor='#77c8b7' />
              <RangeSliderThumb index={1} backgroundColor='#77c8b7' />
            </RangeSlider>
          </Box>
        </WrapItem>
        <WrapItem>
          <Box maxW='sm' borderWidth='1px' borderRadius='lg' overflow='hidden' padding='10px' minWidth='280px' backgroundColor='white' minHeight='100px'>
            <Box
              color='gray.500'
              fontWeight='semibold'
              letterSpacing='wide'
              fontSize='xs'
              textTransform='uppercase'
              marginTop='5px'
            >
              Odometer
            </Box>

            <Flex>
              <Box
                color='gray'
                fontWeight='semibold'
                letterSpacing='wide'
                fontSize='md'
                textTransform='uppercase'
                marginTop='5px'
              >
                {`${odometerLow.toString().replace(/\d(?=(\d{3})+\.)/g, '$&,')} kms`}
              </Box>
              <Spacer/>
              <Box
                color='gray'
                fontWeight='semibold'
                letterSpacing='wide'
                fontSize='sm'
                textTransform='uppercase'
                marginTop='5px'
              >
                -
              </Box>
              <Spacer/>
              <Box
                color='gray'
                fontWeight='semibold'
                letterSpacing='wide'
                fontSize='md'
                textTransform='uppercase'
                marginTop='5px'
              >
                {`${odometerHigh.toString().replace(/\d(?=(\d{3})+\.)/g, '$&,')} kms`}
              </Box>
            </Flex>

            <RangeSlider aria-label={['min', 'max']} 
              min={0} 
              max={500000} 
              step={20000} 
              defaultValue={[0, 300000]}
              onChange={(vals) => {
                setOdometerLow(vals[0])
                setOdometerHigh(vals[1])
              }}
              onChangeEnd={() => search()}>
              <RangeSliderTrack>
                <RangeSliderFilledTrack />
              </RangeSliderTrack>
              <RangeSliderThumb index={0} backgroundColor='#77c8b7' />
              <RangeSliderThumb index={1} backgroundColor='#77c8b7' />
            </RangeSlider>
          </Box>
        </WrapItem>
      </Wrap>

      <Modal width='100%' size='6xl' isOpen={isOpen} onClose={onClose}>
        <ModalOverlay width='100%' height='100%' />
        <ModalContent width='100%'>
          <ModalHeader>{selectedCar ? selectedCar.make : ''} &bull; {selectedCar ? selectedCar.model : ''}</ModalHeader>
          <ModalCloseButton />
          <ModalBody width='100%' >
            <Carousel showStatus={false} showThumbs={false} infiniteLoop>
              {selectedCar ? (selectedCar.images.map((img, index) => {
                return <Image key={index} src={`https://cars-4-u-media.s3.af-south-1.amazonaws.com/${img}`} />;
              })) : []}
            </Carousel>
          </ModalBody>
        </ModalContent>
      </Modal>

      {isLoading === true ? (
      <div style={{ height: '100%', position: 'relative', width: '100vw', minHeight: '400px', paddingTop: '100px' }} >
        <Center>
          <Spinner
            thickness='8px'
            speed='0.65s'
            emptyColor='gray.200'
            color='blue.500'
            size='xl'
          />
        </Center>
        </div>
      ) : (
        filteredCars.length === 0 ? 
          <Center>
            <Text width='100%' zIndex={1} textAlign='center' fontSize={[ '20px', '40px' ]} padding='10' marginTop={50} marginBottom={50} color='gray'>
              Sorry, we couldn't find anything that matches your criteria. Please refine your search.
            </Text>
          </Center>
          : 
          <Wrap spacing='30px' justify='center' margin='10px' width='auto'>
            {filteredCars.map((car, index) => {
              return <WrapItem key={index}>
                <Box maxW='lg' borderWidth='1px' borderRadius='lg' overflow='hidden' backgroundColor='white'>
                  <Carousel showStatus={false} showThumbs={false} infiniteLoop>
                    {car.images.map((img, index) => {
                      return <Image key={index} src={`https://cars-4-u-media.s3.af-south-1.amazonaws.com/${img}`} />;
                    })}
                  </Carousel>
  
                  <Box onClick={() => setSelectedCar(car)} cursor={canOpenModal ? 'pointer' : 'auto'} p='6'>
                    <Box display='flex' alignItems='baseline'>
                      <Badge borderRadius='full' px='2' colorScheme='teal' fontSize='1.1em'>
                        { `R${Number(car.price).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}` }
                      </Badge>
                    </Box>
  
                    <Box
                      color='gray.500'
                      fontWeight='semibold'
                      letterSpacing='wide'
                      fontSize='md'
                      textTransform='uppercase'
                      marginTop='5px'
                    >
                      {car.make} &bull; {car.model}
                    </Box>
  
                    <Box
                      mt='1'
                      fontWeight='semibold'
                      as='h4'
                      lineHeight='tight'
                      noOfLines={5}
                    >
                      {car.description}
                    </Box>
  
                    <Box>
                      { `${car.odometer} kms` }
                    </Box>
                  </Box>
                </Box>
              </WrapItem>
            })}
          </Wrap>
      )}

      <div style={{ height: '100%', padding: '250px 0px 0px 0px', position: 'relative', width: '100%' }} >
        <Image id='contact' src={about} position='absolute' margin='-100px 0px 0px 0px' height='100%' minWidth='400px' objectFit='cover' />
        <div style={{ height: '100%', margin: '-100px 0px 0px 0px', position: 'absolute', width: '100%', backgroundColor: 'black', opacity: 0.7 }} />

        <Center>
          <Text width='100%' zIndex={1} textAlign='center' fontSize={[ '38px', '52px' ]} paddingLeft='10' paddingRight='10' paddingBottom='10' color='white'>Contact Us</Text>
        </Center>

        <SimpleGrid minChildWidth='400px' spacingX='40px' columns={2}>
          <Center margin='10px'>
            <Box width={[ 'sm', 'md', 'lg', 'xl' ]} height={[ 'sm', 'md', 'lg', 'xl' ]} borderWidth='1px' borderRadius='lg' overflow='hidden' zIndex={1} margin='0px' backgroundColor='whiteAlpha.500'>
              <Center width='100%' backgroundColor='white' paddingBottom='15px'>
                <Image src={logoGif} objectFit='cover' width='70%' style={{ marginTop: '10px' }} />
              </Center>

              <Flex flexDirection='column' height='100%'>
                <Center height='70%'>
                  <Grid templateRows='repeat(4, 2fr)' height='70%'>
                    <GridItem rowStart={1}>
                      <Box
                        color='whiteAlpha.600'
                        fontWeight='semibold'
                        letterSpacing='wide'
                        fontSize='md'
                        textTransform='uppercase'
                        marginTop='5px'
                      >
                        Phone
                      </Box>

                      <Link href='tel:0839650233' color='white' textAlign='start' fontSize={[ '18px', '28px' ]}>
                        083 965 0233
                      </Link>
                    </GridItem>
                    <GridItem rowStart={2}>
                      <Box
                        color='whiteAlpha.600'
                        fontWeight='semibold'
                        letterSpacing='wide'
                        fontSize='md'
                        textTransform='uppercase'
                        marginTop='5px'
                      >
                        Email
                      </Box>

                      <Link href='mailto:mathew1cars4u@gmail.com' color='white' textAlign='start' fontSize={[ '18px', '28px' ]}>
                        mathew1cars4u@gmail.com
                      </Link>
                    </GridItem>
                    <GridItem rowStart={3}>
                      <Box
                        color='whiteAlpha.600'
                        fontWeight='semibold'
                        letterSpacing='wide'
                        fontSize='md'
                        textTransform='uppercase'
                        marginTop='5px'
                      >
                        Address
                      </Box>

                      <Link href='https://maps.google.com/?q=10 South St, Boksburg, 1459, South Africa' color='white' textAlign='start' fontSize={[ '18px', '28px' ]}>
                        10 South St, Boksburg, 1459, South Africa
                      </Link>
                    </GridItem>
                    <GridItem rowStart={4}>
                      <Box
                        color='whiteAlpha.600'
                        fontWeight='semibold'
                        letterSpacing='wide'
                        fontSize='md'
                        textTransform='uppercase'
                        marginTop='5px'
                      >
                        Facebook
                      </Box>

                      <Link href='https://www.facebook.com/Cars4uboksburg' color='white' textAlign='start' fontSize={[ '18px', '28px' ]}>
                        Cars 4 U
                      </Link>
                    </GridItem>
                  </Grid>
                </Center>
              </Flex>
            </Box>
          </Center>
          <Center margin='10px'>
            <Box width={[ 'sm', 'md', 'lg', 'xl' ]} height={[ 'sm', 'md', 'lg', 'xl' ]} borderWidth='1px' borderRadius='lg' overflow='hidden' backgroundColor='white' zIndex={1}>
              <iframe title='Map'
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3579.2383955560426!2d28.239296639473878!3d-26.221442401248208!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1e951712ab590291%3A0x281fa5b63588d6a5!2s10%20South%20St%2C%20Boksburg%2C%201459%2C%20South%20Africa!5e0!3m2!1sen!2snz!4v1665090322942!5m2!1sen!2snz"
                style={{ border: 0, width: '100%', height: '100%' }} 
                loading="lazy" 
                referrerPolicy="no-referrer-when-downgrade">
              </iframe>
            </Box>
          </Center>
        </SimpleGrid>
      </div>
    </ChakraProvider>
  );
}

export default Home;
