/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import {
  ChakraProvider,
  Image, 
  Text, 
  Center, 
  Button, 
  Wrap, 
  WrapItem, 
  Box, 
  Input, 
  Textarea,
  Badge,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  Spinner,
  useDisclosure
} from '@chakra-ui/react';
import { Carousel } from "react-responsive-carousel";
import Resizer from "react-image-file-resizer";
import ImageUploader from "react-images-upload";
import { useNavigate } from "react-router-dom";
import { Auth } from 'aws-amplify';
import awsconfig from '../aws-exports';
import { getCars, createCar, deleteCar } from '../lib/api';

Auth.configure(awsconfig);

function Admin() {
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure()
  const cancelRef = React.useRef()

  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingCars, setIsLoadingCars] = useState(true);
  const [authenticated, setIsAuthenticated] = useState(null);
  const [cars, setCars] = useState(true);
  const [isDeletingCar, setIsDeletingCar] = useState(false);
  const [make, setMake] = useState('');
  const [model, setModel] = useState('');
  const [price, setPrice] = useState(0);
  const [odometer, setOdometer] = useState(0);
  const [description, setDescription] = useState('');
  const [images, setImages] = useState([]);
  const [isSaving, setIsSaving] = useState(false);
  const [carForDeletion, setCarForDeletion] = useState(null);

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then(() => {
        setIsAuthenticated(true)
        load()
      })
      .catch((err) => setIsAuthenticated(false));
  }, []);

  useEffect(() => {
    if (authenticated === false) {
      return navigate('/login')
    }

    setIsLoading(false)
  }, [authenticated]);

  useEffect(() => {
    console.log(carForDeletion);
    if (carForDeletion !== null) onOpen();
  }, [carForDeletion]);

  const load = () => getCars().then((res) => {
    const { cars } = res;
    setCars(cars);
    setIsLoadingCars(false);
  })

  const resizeFile = (file) => new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      1000,
      1000,
      "JPEG",
      75,
      0,
      (uri) => {
        resolve(uri);
      },
      "base64"
    );
  });

  const onDrop = async (pictureFiles, ) => {
    const images = await Promise.all(pictureFiles.map((p) => resizeFile(p)));
    setImages(images.map((img) => img.split('base64,').pop()))
  };

  const save = async () => {
    setIsSaving(true);
    await createCar({
      images,
      make,
      model,
      price,
      odometer,
      description
    });
    window.location.reload(false);
  };

  const deleteCarFromList = async (carId, make) => {
    setIsDeletingCar(true);
    await deleteCar(carId, make);
    setIsDeletingCar(false);
    onClose();
    setCarForDeletion(null);
    window.location.reload(false);
  }

  const setDeleteCandidate = (car) => setCarForDeletion(car);

  return (
    <ChakraProvider>
      {isLoading ? (
        <Center>
          <Spinner
            thickness='4px'
            speed='0.65s'
            emptyColor='gray.200'
            color='blue.500'
            size='xl'
          />
        </Center>
      ) : (
        <Box>
          <Text width='100%' zIndex={1} textAlign='center' fontSize={[ '38px', '52px' ]} margin={2}>New</Text>
          <Input
            placeholder="Make"
            size="md"
            onChange={(e) => setMake(e.target.value)}
            margin={2}
            />
          <Input
            placeholder="Model"
            size="md"
            onChange={(e) => setModel(e.target.value)}
            margin={2}
            />
          <Input
            placeholder="Price"
            size="md"
            type="number"
            onChange={(e) => setPrice(e.target.value)}
            margin={2}
            />
          <Input
            placeholder="Odometer"
            size="md"
            type="number"
            onChange={(e) => setOdometer(e.target.value)}
            margin={2}
            />
          <Textarea placeholder='Description'
            onChange={(e) => setDescription(e.target.value)}
            margin={2} />
          <ImageUploader
            withIcon={false}
            withLabel={false}
            withPreview={true}
            buttonText={"Add photos"}
            fileSizeError={"File size is too big!"}
            fileTypeError={"This extension is not supported!"}
            onChange={(x, y) => onDrop(x, y)}
            maxFileSize={102400000}
          />
          <Button colorScheme='purple' isLoading={isSaving} onClick={() => save()}>Add</Button>

          <Text width='100%' zIndex={1} textAlign='center' fontSize={[ '38px', '52px' ]} margin={2}>Stock</Text>
          {isLoadingCars === true ? (
            <Center>
              <Spinner
                thickness='8px'
                speed='0.65s'
                emptyColor='gray.200'
                color='blue.500'
                size='xl'
              />
            </Center>
          ) : (
            cars.length === 0 ? 
              <Center>
                <Text width='100%' zIndex={1} textAlign='center' fontSize={[ '20px', '40px' ]} padding='10' marginTop={50} marginBottom={50} color='gray'>
                  Sorry, we couldn't find anything that matches your criteria. Please refine your search.
                </Text>
              </Center>
              : 
              <Wrap spacing='30px' justify='center' margin='10px' width='auto'>
                {cars.map((car, index) => {
                  return <WrapItem key={index}>
                    <Box maxW='lg' borderWidth='1px' borderRadius='lg' overflow='hidden' backgroundColor='white'>
                      <Carousel showStatus={false} showThumbs={false} infiniteLoop>
                        {car.images.map((img, index) => {
                          return <Image key={index} src={`https://cars-4-u-media.s3.af-south-1.amazonaws.com/${img}`} />;
                        })}
                      </Carousel>
      
                      <Box p='6'>
                        <Box display='flex' alignItems='baseline'>
                          <Badge borderRadius='full' px='2' colorScheme='teal' fontSize='1.1em'>
                            { `R${Number(car.price).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}` }
                          </Badge>
                        </Box>
      
                        <Box
                          color='gray.500'
                          fontWeight='semibold'
                          letterSpacing='wide'
                          fontSize='md'
                          textTransform='uppercase'
                          marginTop='5px'
                        >
                          {car.make} &bull; {car.model}
                        </Box>
      
                        <Box
                          mt='1'
                          fontWeight='semibold'
                          as='h4'
                          lineHeight='tight'
                          noOfLines={5}
                        >
                          {car.description}
                        </Box>
      
                        <Box>
                          { `${car.odometer} kms` }
                        </Box>
                      </Box>
                      <Button colorScheme='red' onClick={() => setDeleteCandidate(car)}>Delete</Button>

                      <AlertDialog
                        isOpen={isOpen}
                        leastDestructiveRef={cancelRef}
                        onClose={onClose}
                      >
                        <AlertDialogOverlay>
                          <AlertDialogContent>
                            <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                              Delete Customer
                            </AlertDialogHeader>

                            <AlertDialogBody>
                              { `Are you sure you want to delete ${carForDeletion ? carForDeletion.make : ''} ${carForDeletion ? carForDeletion.model : ''}? You can't undo this action afterwards.` }
                            </AlertDialogBody>

                            <AlertDialogFooter>
                              <Button ref={cancelRef} onClick={onClose}>
                                Cancel
                              </Button>
                              <Button colorScheme='red' isLoading={isDeletingCar} onClick={() => deleteCarFromList(carForDeletion ? carForDeletion.carId : '', carForDeletion ? carForDeletion.make : '')} ml={3}>
                                Delete
                              </Button>
                            </AlertDialogFooter>
                          </AlertDialogContent>
                        </AlertDialogOverlay>
                      </AlertDialog>
                    </Box>
                  </WrapItem>
                })}
              </Wrap>
          )}
        </Box>
      )}
    </ChakraProvider>
  );
}

export default Admin;
